import { createSlice } from '@reduxjs/toolkit'
import { ILicenseState } from './license.interfaces'
import {
  getCurrentUserLicense,
  getLicenses,
  generateLicense,
  renewLicense,
  editLicense,
  cancelLicense,
  resendLicenseEmail
} from '@/store/actions/license.actions'
const initialState: ILicenseState = {
  licenses: {
    licenses: [],
    totalCount: 0
  },
  license: null,
  licensesError: null
}

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setLicenses(state, action) {
      state.licenses = action.payload
    },
    setLicense: (state, action) => {
      state.license = action.payload
    },
    removeLicense: (state) => {
      state.license = null
    },
    setLicensesError: (state, action) => {
      state.licensesError = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCurrentUserLicense.fulfilled, (state, action) => {
        state.license = action.payload
      })
      .addCase(getLicenses.fulfilled, (state, action) => {
        state.licenses = action.payload
      })
      .addCase(generateLicense.fulfilled, (state, action) => {
        state.licenses = action.payload
      })
      .addCase(renewLicense.fulfilled, (state, action) => {
        state.licenses = action.payload
      })
      .addCase(editLicense.fulfilled, (state, action) => {
        state.licenses = action.payload
      })
      .addCase(cancelLicense.fulfilled, (state, action) => {
        state.licenses = action.payload
      })
      .addCase(resendLicenseEmail.fulfilled, () => {
        console.log('License Email Sending Fulfilled')
      })
  }
})

export const { setLicense, setLicenses, removeLicense, setLicensesError } = licenseSlice.actions

export default licenseSlice.reducer
