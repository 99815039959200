import { Title, useMantineColorScheme } from '@mantine/core'
import { PageTitleProps } from './PageTitle.interfaces'
import styles from './PageTitle.module.css'
import { ColorSchemes } from '@/enums/colorSchemes'
import Card from '../Card/Card'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

function PageTitle({ title, titleNumber = 1, hasBackButton, centered = false }: PageTitleProps) {
  const { colorScheme } = useMantineColorScheme()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className={`${styles.TitleWrapper} ${centered && styles.CenteredTitle}`}>
      {hasBackButton && (
        <Card className={styles.BackButton} role="button" data-testid="back-button" onClick={goBack}>
          <ChevronLeftIcon className={styles.BackIcon} />
        </Card>
      )}
      <Title
        data-testid="page-title"
        order={titleNumber}
        aria-label={`page-title-${colorScheme}`}
        className={`${styles.Title}  ${colorScheme === ColorSchemes.DARK ? styles.DarkTitle : ''}`}
      >
        {title}
      </Title>
    </div>
  )
}

export default PageTitle
