import { requests } from '@/libs/axios/axios'
import { AxiosResponse } from 'axios'
import { ChatEndpoints } from '../chat-endpoints'
import { ChatCreateContract } from '@/contracts/chat/ChatCreate.contract'

export const createChat = async (data: { message: string }): Promise<AxiosResponse<ChatCreateContract>> => {
  return requests.post(ChatEndpoints.CREATE_CHAT, data, {
    timeout: 30000
  })
}
