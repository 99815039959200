import styles from './Topbar.module.css'
import Logo from '@/components/atoms/Logo/Logo'
import ToggleTheme from '@/components/atoms/ToggleTheme/ToggleTheme'
import UserMenu from '@/components/atoms/UserMenu/UserMenu'
import NotificationMenu from '@/components/atoms/NotificationMenu/NotificationMenu'
import { NAV_LINKS } from '@/enums/navLinks'
import { logoutUser } from '@/store/actions/auth.actions'
import { useAppDispatch, useAppSelector } from '@/store/hooks/store-hooks'
import { useNavigate } from 'react-router-dom'

function Topbar() {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user)
  const navigate = useNavigate()
  const notificatioData = useAppSelector((state) => state.notificationData)
  const handleLogout = async () => {
    try {
      await dispatch(logoutUser())
    } catch (error) {
      console.log('Error logging session out: ', error)
    } finally {
      navigate(NAV_LINKS.LOGIN, { replace: true })
    }
  }

  return (
    <header className={`${styles.Topbar}`} data-testid="header-topbar">
      <div className={styles.Innertopbar}>
        <Logo alt="Luminous" width="240px" />
        <div className={styles.ActionWrapper}>
          {notificatioData.notifications?.length > 0 && (
            <NotificationMenu notificatioData={notificatioData.notifications} />
          )}
          <UserMenu user={user} logout={handleLogout} />
          <ToggleTheme />
        </div>
      </div>
    </header>
  )
}

export default Topbar
