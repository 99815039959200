import { createSlice } from '@reduxjs/toolkit'
import { IAuditRuleSetState } from './auditRuleSet.interfaces'
const initialState: IAuditRuleSetState = {
  auditRuleSet: {
    auditRuleSet: []
  }
}
const auditRuleSet = createSlice({
  name: 'auditRuleSet',
  initialState,
  reducers: {
    setAuditRuleSet(state, action) {
      state.auditRuleSet.auditRuleSet = action.payload?.data
    }
  }
})

export const { setAuditRuleSet } = auditRuleSet.actions

export default auditRuleSet.reducer
