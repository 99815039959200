import { Button, Menu } from '@mantine/core'
import { UserMenuProps } from './UserMenu.interfaces'
import styles from './UserMenu.module.css'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
// import { NAV_LINKS } from '@/enums/navLinks'
import { userMenuRoutes } from './user-menu.routes'

function UserMenu({ user, logout }: UserMenuProps) {
  const navigate = useNavigate()

  return (
    user && (
      <Menu shadow="md" width={200} data-testid="user-menu">
        <Menu.Target data-testid="menu-trigger">
          <Button
            size="sm"
            aria-label="User menu"
            className={styles.MenuButton}
            leftSection={<img src="https://avatars.dicebear.com/api/avataaars/luminous.svg" alt={user.name} />}
            rightSection={<ChevronDownIcon className={styles.Icon} />}
          >
            {`${user?.name.substring(0, 16)}${user?.name?.length > 16 ? '...' : ''}`}
          </Button>
        </Menu.Target>

        <Menu.Dropdown className={styles.Dropdown} data-testid="menu-dropdown">
          {userMenuRoutes.map((route) =>
            route.roles.includes(user.role.name) ? (
              <Menu.Item
                key={route.route}
                onClick={() => navigate(route.route)}
                className={styles.DropdownItem}
                data-testid={route.testId}
              >
                {route.label}
              </Menu.Item>
            ) : null
          )}
          <Menu.Item aria-label="logout" onClick={logout} className={styles.DropdownItem} data-testid="logout-button">
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    )
  )
}

export default UserMenu
