'use client'
import { useState, useEffect, useCallback } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Topbar from '../molecules/Topbar/Topbar'
import styles from './AppLayout.module.css'
import SideNavigation from '../molecules/SideNavigation/SideNavigation'
import Notification from '../atoms/Notification/Notification'
import { useAppDispatch, useAppSelector } from '@/store/hooks/store-hooks'
import { clearNotification } from '@/store/slices/notification/notification.slice'
import Modal from '@/components/atoms/Modal/Modal'
import { openModal, clearModal } from '@/store/slices/modal/modal.slice'
import SessionExpireAlertModal from './components/sessionExpireAlertModal/SessionExpireAlertModal'
import { NAV_LINKS } from '@/enums/navLinks'
import { logoutUser } from '@/store/actions/auth.actions'
import { getReportResultPolling } from '@/store/actions/reports.actions'

function AppLayout() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [timeLeft, setTimeLeft] = useState(600)
  const [alertModal, setAlertModal] = useState(false)
  const notification = useAppSelector((state) => state.notification)
  const uploadedPendingReportsIds = useAppSelector((state) => state.reports.reportsPollingIds)
  const isReportUploading = useAppSelector((state) => state.reports.isUploading)

  const closeNotification = () => dispatch(clearNotification())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer)
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(logoutUser())
    } catch (error) {
      console.log('Error logging session out: ', error)
    } finally {
      navigate(NAV_LINKS.LOGIN, { replace: true })
    }
  }, [dispatch, navigate])

  const handleOpenModal = useCallback(async () => {
    dispatch(openModal({ title: 'Session Expiration Alert' }))
  }, [dispatch])

  useEffect(() => {
    // console.log(timeLeft, 'timeLeft')
    if (timeLeft === 120) {
      handleOpenModal()
      setAlertModal(true)
    } else if (timeLeft === 0) {
      handleLogout()
    }
  }, [timeLeft, handleLogout, handleOpenModal])
  useEffect(() => {
    const fetchReportResultPolling = () => {
      if (!isReportUploading && uploadedPendingReportsIds?.length > 0) {
        for (const reportId of uploadedPendingReportsIds) {
          dispatch(getReportResultPolling({ reportId }))
        }
      }
    }

    fetchReportResultPolling()
  }, [uploadedPendingReportsIds, isReportUploading, dispatch])
  const handleClickMain = () => {
    setTimeLeft(600)
  }

  const handleCloseModal = () => {
    setAlertModal(false)
    dispatch(clearModal())
  }

  return (
    <main className={styles.MainApp} onClick={handleClickMain}>
      <Topbar />
      <SideNavigation />
      <article className={styles.MainContent}>
        <Outlet />
      </article>
      <Notification
        isOpen={notification.show}
        variant={notification.variant}
        message={notification.message}
        onClose={closeNotification}
      />
      {alertModal && (
        <Modal onClose={handleCloseModal}>
          <SessionExpireAlertModal onCancel={handleCloseModal} timeLeft={timeLeft} />
        </Modal>
      )}
    </main>
  )
}

export default AppLayout
