import { AxiosResponse } from 'axios'
import { requests } from '@/libs/axios/axios'
const saveReportToUrl = async (url: string, fields: FormData): Promise<AxiosResponse> => {
  return requests.post(url, fields, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 2 * 60 * 1000
  })
}

export default saveReportToUrl
