import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NotificationDataState, NotificationData } from './notificationData.interfaces'

const initialState: NotificationDataState = {
  notifications: []
}

const notificationDataSlice = createSlice({
  name: 'notificationData',
  initialState,
  reducers: {
    setNotificationData: (state, action: PayloadAction<Pick<NotificationData, 'message' | 'id'>>) => {
      state.notifications.push({
        message: action.payload.message,
        id: action.payload.id
      })
    },
    clearNotificationData: () => {
      return initialState
    }
  }
})

export const { setNotificationData, clearNotificationData } = notificationDataSlice.actions

export default notificationDataSlice.reducer
