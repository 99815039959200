/* eslint-disable @typescript-eslint/naming-convention */
import chatApi from '@/services/chat/chat.api'
import { createAsyncAction } from '../utils/createAsyncAction'
import getErrorMessage from '@/utils/getErrorMessage'
import { setNotification } from '../slices/notification/notification.slice'
import { setHistoryChat, setMostAskedQuestions } from '../slices/chat/chat.slice'
import { ChatMessageContract } from '@/contracts/chat/ChatMessage.contract'

export const getChatHistory = createAsyncAction<unknown, undefined>(
  'chat/getHistory',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await chatApi.getHistory()
      const history = response.data.map((item) => ({
        id: item.id,
        question: item.firstMessage
      }))

      dispatch(setHistoryChat(history))
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)

export const getMostAskedQuestions = createAsyncAction<unknown, undefined>(
  'chat/getMostAskedQuestions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await chatApi.getMostAskedQuestions()
      dispatch(setMostAskedQuestions(response.data))
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)
export const createChat = createAsyncAction<ChatMessageContract, { body: { message: string } }>(
  'chat/create',
  async ({ body }, { dispatch, rejectWithValue }) => {
    try {
      const response = await chatApi.createChat(body)
      // dispatch(setCreatedChat(response.data))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)

export const sendMessage = createAsyncAction<
  ChatMessageContract,
  { message: string; chat_id: string; path_id: string }
>('chat/sendMessage', async ({ message, path_id, chat_id }, { dispatch, rejectWithValue }) => {
  try {
    const response = await chatApi.sendMessage({ message, path_id, chat_id })
    return response.data
  } catch (error) {
    const message = getErrorMessage(error)

    dispatch(setNotification({ message, variant: 'error' }))

    return rejectWithValue(message)
  }
})

export const regenerateMessage = createAsyncAction<
  ChatMessageContract,
  { message_id: string; path_id: string; chat_id: string }
>('chat/regenerateMessage', async ({ message_id, path_id, chat_id }, { dispatch, rejectWithValue }) => {
  try {
    const response = await chatApi.regenerateMessage({ message_id, path_id, chat_id })
    return response.data
  } catch (error) {
    const message = getErrorMessage(error)

    dispatch(setNotification({ message, variant: 'error' }))

    return rejectWithValue(message)
  }
})

export const sendFeedback = createAsyncAction<
  unknown,
  { message_id: string; answer_feedback: number; chat_id: string; path_id: string }
>('chat/sendFeedback', async ({ message_id, answer_feedback, chat_id, path_id }, { dispatch, rejectWithValue }) => {
  try {
    await chatApi.sendFeedback({ message_id, answer_feedback, chat_id, path_id })
    dispatch(setNotification({ message: 'Feedback sent successfully!', variant: 'success' }))
  } catch (error) {
    const message = getErrorMessage(error)

    dispatch(setNotification({ message, variant: 'error' }))

    return rejectWithValue(message)
  }
})
