import generateReport from './generateReport/generateReport'
import { getReportResult, getReportStatus } from './getReportResult/getReportResult'
import getReports from './getReports/getReports'
import getUploadUrl from './getUploadUrl/getUploadUrl'
import saveReportToUrl from './saveReportToUrl/saveReportToUrl'
import updateAuditData from './updateAuditData/updateAuditData'

export default {
  getReports,
  getReportResult,
  generateReport,
  getUploadUrl,
  saveReportToUrl,
  updateAuditData,
  getReportStatus
}
