import { requests } from '@/libs/axios/axios'
import { MetricsEndpoints } from '../metrics-endpoints.enums'
import { ChartRanges } from '@/types/chart-ranges'
import { AxiosResponse } from 'axios'
import { ChartsDataReponse } from '../metrics.interfaces'

export const getMetrics = async (
  organizationId: number,
  frequency: ChartRanges,
  filter = 'all',
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ChartsDataReponse[]>> => {
  return requests.get(
    `${MetricsEndpoints.METRICS}?organization=${organizationId}&frequency=${frequency}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`
  )
}
