import { NAV_LINKS } from '@/enums/navLinks'
import { USER_ROLES } from '@/enums/userRoles'
import { RouteProps } from 'react-router-dom'

export type RoleBasedRouteProps = RouteProps & { roles: USER_ROLES[]; subRoutes?: RouteProps[] }

export const publicRoutes: RouteProps[] = [
  {
    path: NAV_LINKS.LOGIN,
    async lazy() {
      const Login = await import('../pages/login/LoginPage')
      return { Component: Login.default }
    }
  },
  {
    path: NAV_LINKS.FORGOT_PASSWORD,
    async lazy() {
      const ForgotPassword = await import('../pages/forgotPassword/ForgotPasswordPage')
      return { Component: ForgotPassword.default }
    }
  },
  {
    path: NAV_LINKS.RESET_PASSWORD,
    async lazy() {
      const ResetPassword = await import('@/pages/resetPassword/ResetPasswordPage')
      return { Component: ResetPassword.default }
    }
  },
  {
    path: NAV_LINKS.MFA_VERIFICATION,
    async lazy() {
      const MfaVerification = await import('@/pages/mfaVerification/MfaVerificationPage')
      return { Component: MfaVerification.default }
    }
  },
  {
    path: NAV_LINKS.REGISTER,
    async lazy() {
      const Register = await import('../pages/register/RegisterPage')
      return { Component: Register.default }
    }
  }
  // {
  //   path: NAV_LINKS.CONTACT_US,
  //   async lazy() {
  //     const ContactUs = await import('../pages/contactUs/ContactUsPage')
  //     return { Component: ContactUs.default }
  //   }
  // },
  // {
  //   path: NAV_LINKS.FAQS,
  //   async lazy() {
  //     const faqs = await import('../pages/faqs/faqsPage')
  //     return { Component: faqs.default }
  //   }
  // }
]

export const privateRoutesPerRole: RoleBasedRouteProps[] = [
  {
    path: NAV_LINKS.DASHBOARD,
    async lazy() {
      const Dashboard = await import('../pages/dashboard/DashboardPage')
      return { Component: Dashboard.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.MANAGE_ACCOUNTS,
    async lazy() {
      const ManageAccounts = await import('../pages/manageAccounts/ManageAccountsPage')
      return { Component: ManageAccounts.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN]
  },
  {
    path: NAV_LINKS.LICENSE_PLAN,
    async lazy() {
      const LicensePlan = await import('../pages/licensePlan/LicensePlanPage')
      return { Component: LicensePlan.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.LICENSE_MANAGEMENT,
    async lazy() {
      const LicenseManagement = await import('../pages/licenseManagement/LicenseManagementPage')
      return { Component: LicenseManagement.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.ORGANIZATIONS_MANAGEMENT,
    async lazy() {
      const OrganizationsManagement = await import('../pages/manageOrganizations/ManageOrganizationsPage')
      return { Component: OrganizationsManagement.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    path: NAV_LINKS.ORGANIZATION_LICENSES,
    async lazy() {
      const OrganizationLicenses = await import('../pages/organizationLicenses/OrganizationLicensesPage')
      return { Component: OrganizationLicenses.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    path: NAV_LINKS.LICENSES_ADMINISTRATION,
    async lazy() {
      const LicensesAdmin = await import('../pages/licensesAdmin/LicensesAdminPage')
      return { Component: LicensesAdmin.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    path: NAV_LINKS.REPORTS_AUDITED,
    async lazy() {
      const Reports = await import('../pages/reports/ReportsPage')
      return { Component: Reports.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.REPORT_ANALYSIS,
    async lazy() {
      const ReportAnalysis = await import('../pages/reportAnalysis/ReportAnalysisPage')
      return { Component: ReportAnalysis.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.AI_CHAT,
    async lazy() {
      const AIChat = await import('../pages/AIChat/AIChatScreen')
      return { Component: AIChat.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.CONTACT_US,
    async lazy() {
      const ContactUs = await import('../pages/contactUs/ContactUsPage')
      return { Component: ContactUs.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.FAQS,
    async lazy() {
      const faqs = await import('../pages/faqs/faqsPage')
      return { Component: faqs.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  },
  {
    path: NAV_LINKS.SETTINGS,
    async lazy() {
      const settings = await import('@/components/templates/SettingsTemplate/SettingsTemplate')
      return { Component: settings.default }
    },
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE]
  }
]
