export const enum NAV_LINKS {
  DASHBOARD = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  MFA_VERIFICATION = '/mfa-verification',
  REGISTER = '/register',
  MANAGE_ACCOUNTS = '/manage-accounts',
  LICENSE_PLAN = '/license-plan',
  LICENSE_MANAGEMENT = '/license-management',
  LICENSES_ADMINISTRATION = '/licenses-admin',
  ORGANIZATIONS_MANAGEMENT = '/organizations-management',
  ORGANIZATION_LICENSES = '/organizations/:organizationId/licenses',
  REPORTS_AUDITED = '/reports-audited',
  REPORT_ANALYSIS = '/report-analysis',
  AI_CHAT = '/ai-chat',
  CONTACT_US = '/contact-us',
  FAQS = '/faqs',
  SETTINGS = '/settings',
  NOT_FOUND = '*'
}
