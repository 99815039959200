import { NAV_LINKS } from '@/enums/navLinks'
import { getRedirectPath } from '@/helpers/authentication/redirection'
import { refreshSession, verifyTermOfServices } from '@/store/actions/auth.actions'
import { getCurrentUserLicense } from '@/store/actions/license.actions'
import { useAppDispatch, useAppSelector } from '@/store/hooks/store-hooks'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import LicenseGuardProvider from './LicenseGuardProvider'
import { setNotification } from '../store/slices/notification/notification.slice'
import Modal from '@/components/atoms/Modal/Modal'
import TermOfServicesModal from './../components/templates/TermOfServicesModalTemplate/TermOfServicesModal'
import { openModal } from '@/store/slices/modal/modal.slice'

function SessionProvider() {
  const [isLoading, setIsLoading] = useState(true)
  const [licenseShowCount, setLicenseShowCount] = useState(true)
  const [termOfServicesModal, setTermOfServicesModal] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { token, isSignedIn, user, termOfServices } = useAppSelector((state) => state.auth)
  const from = location.state?.from?.pathname ?? location?.pathname
  const licenseData = useAppSelector((state) => state.license.license)
  const dueDate = new Date(String(licenseData?.dueDate))
  const todayDate = new Date()
  const diffInDates = Math.abs(dueDate.getTime() - todayDate.getTime())
  const millisecondsInDay: number = 1000 * 60 * 60 * 24
  const differenceInDays: number = Math.floor(diffInDates / millisecondsInDay)

  const maxAudit = licenseData?.maxAuditsPerDay ?? 0
  const auditUsage = licenseData?.usages?.currentAuditsPerDay ?? 0
  const leftAuditUsage = maxAudit - auditUsage

  const licenseAlertDays = useMemo(() => [30, 45, 60, 90], [])
  const auditAlertCount = useMemo(() => [1, 5, 10], [])

  useEffect(() => {
    setTermOfServicesModal(termOfServices !== 1)
  }, [termOfServices])

  useEffect(() => {
    if (licenseAlertDays.includes(differenceInDays) && (licenseShowCount ?? false)) {
      dispatch(setNotification({ message: `Your license will expire in ${differenceInDays} days`, variant: 'warning' }))
      setLicenseShowCount(false)
    }
  }, [differenceInDays, licenseShowCount, dispatch, setLicenseShowCount, licenseAlertDays])
  useEffect(() => {
    if (termOfServices !== 1) {
      dispatch(openModal({ title: 'Terms and Conditions' }))
    }
  }, [dispatch, termOfServices])

  useEffect(() => {
    if (auditAlertCount.includes(leftAuditUsage)) {
      setTimeout(() => {
        dispatch(
          setNotification({
            message: `Your remaining daily audit report limit is ${leftAuditUsage}`,
            variant: 'warning'
          })
        )
      }, 3000)
    }
  }, [leftAuditUsage, dispatch, auditAlertCount])

  const checkRefreshSession = useCallback(async () => {
    try {
      const refreshResponse = await dispatch(refreshSession()).unwrap()
      if (refreshResponse) {
        const redirectRoute = getRedirectPath(from, refreshResponse.user?.role.name)
        return navigate(redirectRoute, { replace: true })
      } else {
        throw new Error('Cannot refresh session. Redirecting to login page.')
      }
    } catch (error) {
      console.log(error)
      navigate(NAV_LINKS.LOGIN, { replace: true })
      setIsLoading(false)
    }
  }, [dispatch, from, navigate])

  const checkLicense = useCallback(async () => {
    try {
      await dispatch(getCurrentUserLicense())
    } catch (error) {
      console.log('Error getting license: ', error)
    } finally {
      setIsLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (!token) checkRefreshSession()
  }, [token, checkRefreshSession, dispatch])

  useEffect(() => {
    if (isSignedIn) checkLicense()
  }, [isSignedIn, checkLicense, dispatch])
  const handleCloseModal = () => {
    console.log('handleCloseModal')
    setTermOfServicesModal(false)
  }
  const handleSubmitModal = async () => {
    console.log('handleSubmitModal')
    console.log(user, 'user')
    // return;
    console.log('handleSubmitModal')
    setTermOfServicesModal(false)
    try {
      const data = {
        term_of_service: true
      }
      const mfaResponse = await verifyTermOfServices(data)
      console.log(mfaResponse, 'mfaResponse')
    } catch (error) {
      throw new Error('MFA Session not valid or Username not valid')
    }
  }
  return isLoading ? (
    <p>Loading session...</p>
  ) : (
    <LicenseGuardProvider>
      <Outlet />
      {termOfServicesModal && (
        <Modal onClose={handleCloseModal}>
          <TermOfServicesModal onSubmit={handleSubmitModal} />
        </Modal>
      )}
    </LicenseGuardProvider>
  )
}

export default SessionProvider
