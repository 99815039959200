import { requests } from '@/libs/axios/axios'
import { LicenseEndpoints } from '../license-endpoints'

async function getAllLicenses(filters?: string) {
  let url: string = LicenseEndpoints.LICENSE

  if (filters) {
    url = `${url}?${filters}`
  }
  return requests.get(url)
}

export default getAllLicenses
