import { ModalProps } from './Modal.interfaces'
import { Modal as MantineModal } from '@mantine/core'
import styles from './Modal.module.css'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks/store-hooks'
import { clearModal, closeModal } from '@/store/slices/modal/modal.slice'

function Modal({ onClose, centered = true, children, className, size = 'auto', ...props }: ModalProps) {
  const { isOpen, canClose, title } = useAppSelector((state) => state.modal)
  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(clearModal())
      onClose && onClose()
    }, 200)
  }, [dispatch, onClose])

  return (
    <MantineModal
      data-testid="modal"
      overlayProps={{
        'aria-label': 'Modal overlay'
      }}
      className={className}
      withCloseButton={canClose}
      opened={isOpen}
      onClose={handleClose}
      centered={centered}
      title={title}
      closeButtonProps={{
        'aria-label': 'Close modal'
      }}
      classNames={{
        overlay: styles.Overlay,
        content: styles.Modal,
        header: styles.Header,
        body: styles.Body,
        title: styles.Title,
        close: styles.Close
      }}
      size={'100%'}
      {...props}
    >
      {children}
    </MantineModal>
  )
}

export default Modal
