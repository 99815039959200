export const enum MetricsEndpoints {
  USAGE = '/metrics/usage',
  AUDITS = '/metrics/audits',
  TEMPLATES = '/metrics/templates',
  CHAT = '/metrics/chat',
  QUESTIONS = '/metrics/questions',
  ERRORS = '/metrics/errors',
  METRICS = '/audits/metrics',
  UPLOADED_BY_USER = '/metrics/uploadByUser'
}
