import Button from '@/components/atoms/Button/Button'
import styles from './SessionExpireAlertModal.module.css'
import { SessionExpireAlertModalProps } from './SessionExpireAlertModal.interfaces'

function SessionExpireAlertModal({ onCancel, timeLeft }: SessionExpireAlertModalProps) {
  const handleCancel = () => {
    onCancel?.()
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer}>
        <h3 className={styles.mainHeading}>You will be logged out after {timeLeft} seconds</h3>
        <p>Click anywhere on the screen to reset the session</p>
      </div>
      <div className={styles.buttonContainer}>
        <Button label="Cancel" onClick={handleCancel} />
      </div>
    </div>
  )
}
export default SessionExpireAlertModal
