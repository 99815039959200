import { createSlice } from '@reduxjs/toolkit'
import { LicenseLoadersState } from './license-loaders-interface'

const initalState: LicenseLoadersState = {
  isFetchingLicenses: false,
  isFetchingLicense: false,
  isUpdatingLicense: false,
  isGeneratingLicense: false,
  isSendingLicense: false,
  isRequestingLicense: false,
  isRequestingLicenseCancel: false,
  isRenewingLicense: false,
  isActivatingLicense: false,
  isCancellingLicense: false
}

const licenseLoadersSlice = createSlice({
  name: 'licenseLoaders',
  initialState: initalState,
  reducers: {
    setIsFetchingLicenses(state, action) {
      state.isFetchingLicenses = action.payload
    },
    setIsFetchingLicense(state, action) {
      state.isFetchingLicense = action.payload
    },
    setIsUpdatingLicense(state, action) {
      state.isUpdatingLicense = action.payload
    },
    setIsGeneratingLicense(state, action) {
      state.isGeneratingLicense = action.payload
    },
    setIsSendingLicense(state, action) {
      state.isSendingLicense = action.payload
    },
    setIsRequestingLicense(state, action) {
      state.isRequestingLicense = action.payload
    },
    setIsRequestingLicenseCancel(state, action) {
      state.isRequestingLicenseCancel = action.payload
    },
    setIsRenewingLicense(state, action) {
      state.isRenewingLicense = action.payload
    },
    setIsActivatingLicense(state, action) {
      state.isActivatingLicense = action.payload
    },
    setIsCancellingLicense(state, action) {
      state.isCancellingLicense = action.payload
    }
  }
})

export const {
  setIsFetchingLicenses,
  setIsFetchingLicense,
  setIsUpdatingLicense,
  setIsGeneratingLicense,
  setIsSendingLicense,
  setIsRequestingLicense,
  setIsRequestingLicenseCancel,
  setIsRenewingLicense,
  setIsActivatingLicense,
  setIsCancellingLicense
} = licenseLoadersSlice.actions

export default licenseLoadersSlice.reducer
