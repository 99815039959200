import { getHistory } from './getHistory/getHistory'
import { getMostAskedQuestions } from './getMostAskedQuestions/getMostAskedQuestions'
import { createChat } from './createChat/createChat'
import { sendMessage } from './sendMessage/sendMessage'
import { regenerateMessage } from './regenerateMessage/regenerateMessage'
import { sendFeedback } from './sendFeedback/sendFeedback'
export default {
  getHistory,
  getMostAskedQuestions,
  createChat,
  sendMessage,
  regenerateMessage,
  sendFeedback
}
