import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ModalState } from './modal.interface'

const initialState: ModalState = {
  isOpen: false,
  title: '',
  canClose: true
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload }: PayloadAction<{ title: string; canClose?: boolean }>) => {
      state.isOpen = true
      state.title = payload.title
      state.canClose = payload.canClose ?? true
    },
    closeModal: (state) => {
      state.isOpen = false
    },
    clearModal: () => {
      return initialState
    }
  }
})

export const { openModal, closeModal, clearModal } = modalSlice.actions

export default modalSlice.reducer
