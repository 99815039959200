import { requests } from '@/libs/axios/axios'
import { MetricsEndpoints } from '../metrics-endpoints.enums'
import { AxiosResponse } from 'axios'
import { ChartResponseData } from '../metrics.interfaces'

const getUsageMetrics = async (organizationId: number, filter?: string): Promise<AxiosResponse<ChartResponseData>> => {
  return requests.get(`${MetricsEndpoints.USAGE}?organizationId=${organizationId}&filter=${filter}`)
}

export default getUsageMetrics
