import { requests } from '@/libs/axios/axios'
import { MetricsEndpoints } from '../metrics-endpoints.enums'
import { AxiosResponse } from 'axios'
import { UploadedReportByUserContract } from '../metrics.interfaces'

const getTemplatesMetrics = async (
  organizationId: number,
  filter?: string,
  startDate?: string,
  endDate?: string
): Promise<AxiosResponse<UploadedReportByUserContract[]>> => {
  return requests.get(
    `${MetricsEndpoints.UPLOADED_BY_USER}?organizationId=${organizationId}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`
  )
}

export default getTemplatesMetrics
