import { AxiosResponse } from 'axios'
import { ReportsEndpoints } from '../reportsEndpoints'
import { requests } from '@/libs/axios/axios'
import { ReportAuditData } from '../reports.interfaces'

const updateAuditData = async (reportId: string, data: ReportAuditData): Promise<AxiosResponse> => {
  return requests.patch(`${ReportsEndpoints.AUDIT_REPORTS}/${reportId}`, data)
}

export default updateAuditData
