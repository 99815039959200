import getOrganizationUsers from './getOrganizationUsers/getOrganizationUsers'
import editOrganization from './editOrganization/editOrganization'
import getOrganizationById from './getOrganizationById/getOrganizationById'
import getCurrentUserOrganization from './getCurrentUserOrganization/getCurrentUserOrganization'
import getOrganizations from './getOrganizations/getOrganizations'
import editOrganizationUser from './editOrganizationUser/editOrganizationUser'
import deleteOrganizationUser from './deleteOrganizationUser/deleteOrganizationUser'
import getOrganizationLicenses from './getOrganizationLicenses/getOrganizationLicenses'

export default {
  getOrganizations,
  getOrganizationUsers,
  getOrganizationLicenses,
  getCurrentUserOrganization,
  getOrganizationById,
  editOrganization,
  editOrganizationUser,
  deleteOrganizationUser
}
