import { createSlice } from '@reduxjs/toolkit'
import { ReportLoadersState } from './report-loaders.interfaces'

const initialState: ReportLoadersState = {
  reportsLoading: false,
  reportLoading: false,
  reportUploading: false,
  reportGenerating: false,
  reportUpdating: false
}

const reportLoadersSlice = createSlice({
  name: 'auditLoaders',
  initialState,
  reducers: {
    setReportsLoading: (state, action) => {
      state.reportsLoading = action.payload
    },
    setReportLoading: (state, action) => {
      state.reportLoading = action.payload
    },
    setReportUploading: (state, action) => {
      state.reportUploading = action.payload
    },
    setReportGenerating: (state, action) => {
      state.reportGenerating = action.payload
    },
    setReportUpdating: (state, action) => {
      state.reportUpdating = action.payload
    }
  }
})

export const { setReportsLoading, setReportLoading, setReportGenerating, setReportUploading, setReportUpdating } =
  reportLoadersSlice.actions

export default reportLoadersSlice.reducer
