import { requests } from '@/libs/axios/axios'
import { TermOfServicesVerifyData } from '../auth.interfaces'
import { AuthEndpoints } from '../auth-endpoints.enums'
import { AxiosResponse } from 'axios'
import { LoginResponse } from '@/contracts/auth/AuthResponses.contract'

const verifyTermsOfServices = async (body: TermOfServicesVerifyData): Promise<AxiosResponse<LoginResponse>> => {
  return requests.post(AuthEndpoints.TERMS_OF_SERVICES, body)
}

export default verifyTermsOfServices
