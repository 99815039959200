import { requests } from '@/libs/axios/axios'
import { AxiosResponse } from 'axios'
import { ReportsEndpoints } from '../reportsEndpoints'
import { ReportContract } from '@/contracts/Report.contract'

const getReports = async (filters?: string): Promise<AxiosResponse<ReportContract[]>> => {
  let url: string = ReportsEndpoints.AUDIT_REPORTS

  if (filters) {
    url = `${url}?${filters}`
  }
  return requests.get(url)
}

export default getReports
