import MetrisAPI from '@/services/metrics/metrics.api'
import { createAsyncAction } from '../utils/createAsyncAction'
import getErrorMessage from '@/utils/getErrorMessage'
import { ChartResponseData, UploadedReportByUserContract } from '@/services/metrics/metrics.interfaces'
import { setNotification } from '../slices/notification/notification.slice'
import { ChartRanges } from '@/types/chart-ranges'
import dayjs from 'dayjs'
import { getMetrics } from '@/services/metrics/getMetrics/getMetrics'
import {
  setActionsTaken,
  setReportStatus,
  setReportsViewedAndDownloaded,
  setTotalUploads,
  setHoursSaved
} from '../slices/metrics/metrics.slice'
import { DashboardCharts } from '@/enums/dashboardCharts'
import {
  setIsGettingActionsTaken,
  setIsGettingReportStatus,
  setIsGettingReportViewedAndDownloaded,
  setIsGettingTotalUploads,
  setIsGettingReportHoursSaved
} from '../slices/loaders/metrics-loaders/metrics-loaders.slice'

export const getDashboardData = createAsyncAction<
  unknown,
  { organizationId: number; filter?: DashboardCharts; frequency?: ChartRanges; startDate?: string; endDate?: string }
>(
  'dashboard/getDashboardData',
  async ({ organizationId, filter, frequency = 'monthly', startDate, endDate }, { rejectWithValue, dispatch }) => {
    const dateToStart = !startDate ? dayjs().subtract(1, 'month').format('YYYY-MM-DD') : startDate
    const dateToEnd = !endDate ? dayjs().format('YYYY-MM-DD') : endDate

    try {
      const response = await getMetrics(organizationId, frequency, 'all', dateToStart, dateToEnd)
      const totalUploads = response.data.map((item) => ({
        x: item.key.substring(0, 3),
        y: item.total_report
      }))
      const reportsHoursSaved = response.data.map((item) => ({
        x: item.key.substring(0, 3),
        y: ((item.total_report * 40) / 60).toFixed(2)
      }))
      const reportsStatus = response.data.map((item) => ({
        date: item.key.substring(0, 3),
        'Out of Compliance': item.status.out_of_compliance,
        Invalid: item.status.invalid,
        Warning: item.status.warning
      }))
      const actionsTaken = response.data.map((item) => ({
        date: item.key.substring(0, 3),
        'Closed Cases': item.action_taken.closed_cases,
        'Contacted Energy Companies': item.action_taken.contacted_energy_company,
        'Contacted Testing Companies': item.action_taken.contacted_testing_company,
        'Open Cases': item.action_taken.open_cases
      }))
      const reportsViewedAndDownloaded = response.data.map((item) => ({
        date: item.key.substring(0, 3),
        'Reports Viewed': item.view_downloaded.viewed,
        'Reports Downloaded': item.view_downloaded.downloaded
      }))

      switch (filter) {
        case DashboardCharts.REPORTS_UPLOADED:
          dispatch(setTotalUploads([{ id: 'totalUploads', data: totalUploads }]))
          break
        case DashboardCharts.REPORTS_STATUSES:
          dispatch(setReportStatus(reportsStatus))
          break
        case DashboardCharts.REPORTS_ACTIONS_TAKEN:
          dispatch(setActionsTaken(actionsTaken))
          break
        case DashboardCharts.REPORTS_VIEWED_AND_DOWNLOADED:
          dispatch(setReportsViewedAndDownloaded(reportsViewedAndDownloaded))
          break
        case DashboardCharts.REPORTS_HOURS_SAVED:
          dispatch(setHoursSaved([{ id: 'hoursSaved', data: reportsHoursSaved }]))
          break
        default:
          dispatch(setTotalUploads([{ id: 'totalUploads', data: totalUploads }]))
          dispatch(setReportStatus(reportsStatus))
          dispatch(setActionsTaken(actionsTaken))
          dispatch(setReportsViewedAndDownloaded(reportsViewedAndDownloaded))
          dispatch(setHoursSaved([{ id: 'hoursSaved', data: reportsHoursSaved }]))
          break
      }
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching dashboard data'
      dispatch(setNotification({ message, variant: 'error' }))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsGettingTotalUploads(false))
      dispatch(setIsGettingReportStatus(false))
      dispatch(setIsGettingActionsTaken(false))
      dispatch(setIsGettingReportViewedAndDownloaded(false))
      dispatch(setIsGettingReportHoursSaved(false))
    }
  }
)

export const getUsageData = createAsyncAction<ChartResponseData, { organizationId: number; filter: string }>(
  'dashboard/getUsageData',
  async ({ organizationId, filter }, { rejectWithValue, dispatch }) => {
    try {
      const response = await MetrisAPI.getUsage(organizationId, filter)
      return response.data
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching usage data'
      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)

export const getAuditsData = createAsyncAction<ChartResponseData, undefined>(
  'dashboard/getAuditsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await MetrisAPI.getAudits()
      return response.data
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching audits data'
      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)

export const getTemplatesData = createAsyncAction<ChartResponseData, undefined>(
  'dashboard/getTemplatesData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await MetrisAPI.getTemplates()
      return response.data
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching templates data'
      dispatch(setNotification({ message, variant: 'error' }))
      return rejectWithValue(message)
    }
  }
)

export const getChatData = createAsyncAction<{ question: string; error: string }, undefined>(
  'dashboard/getChatData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await MetrisAPI.getChat()
      return {
        question: response.data.data.chat,
        error: response.data.data.error
      }
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching most frequent data'
      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)

export const getQuestionsData = createAsyncAction<ChartResponseData, undefined>(
  'dashboard/getQuestionsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await MetrisAPI.getQuestions()
      return response.data
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching questions data'
      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    }
  }
)

export const getErrorsData = createAsyncAction<ChartResponseData, undefined>(
  'dashboard/getErrorsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await MetrisAPI.getErrors()
      return response.data
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching errors data'
      dispatch(setNotification({ message, variant: 'error' }))
      return rejectWithValue(message)
    }
  }
)

export const getUploadedByUserData = createAsyncAction<
  UploadedReportByUserContract[],
  { organizationId: number; filter: string; startDate?: string; endDate?: string }
>(
  'dashboard/getUploadedByUserData',
  async ({ organizationId, filter, startDate, endDate }, { rejectWithValue, dispatch }) => {
    try {
      const dateToStart =
        filter === 'monthly' && !startDate
          ? dayjs().subtract(5, 'month').format('YYYY-MM-DD')
          : filter === 'daily' && !startDate
          ? dayjs().subtract(7, 'days').format('YYYY-MM-DD')
          : startDate
      // const dateToEnd = (filter == 'monthly' && !endDate) ? dayjs().format('YYYY-MM-DD') : filter == 'weekly' && !endDate ? dayjs().format('YYYY-MM-DD') : endDate
      const dateToEnd = !endDate ? dayjs().format('YYYY-MM-DD') : endDate

      const response = await MetrisAPI.getUploadedByUserData(organizationId, filter, dateToStart, dateToEnd)
      return response.data
    } catch (error) {
      const message = getErrorMessage(error) ?? 'Error fetching uploaded by user data'
      dispatch(setNotification({ message, variant: 'error' }))
      return rejectWithValue(message)
    }
  }
)
