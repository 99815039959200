import authSlice from './slices/auth/auth.slice'
import authLoadersSlice from './slices/loaders/auth-loaders/auth-loaders.slice'
import usersLoadersSlice from './slices/loaders/users-loaders/users-loaders.slice'
import organizationsLoadersSlice from './slices/loaders/organizations-loaders/organizations-loaders.slice'
import notificationSlice from './slices/notification/notification.slice'
import subscriptionSlice from './slices/subscription/subscription.slice'
import usersSlice from './slices/users/users.slice'
import organizationsSlice from './slices/organizations/organizations.slice'
import metricsSlice from './slices/metrics/metrics.slice'
import reportsSlice from './slices/reports/reports.slice'
import licenseSlice from './slices/license/license.slice'
import licenseLoadersSlice from './slices/loaders/license-loaders/license-loaders.slice'
import chatSlice from './slices/chat/chat.slice'
import chatLoadersSlice from './slices/loaders/chat-loaders/chat-loaders.slice'
import ModalSlice from './slices/modal/modal.slice'
import ReportLoadersSlice from './slices/loaders/report-loaders/report-loaders.slice'
import metricsLoadersSlice from './slices/loaders/metrics-loaders/metrics-loaders.slice'
import notificationDataSlice from './slices/notificationData/notificationData.slice'
import entitiySlice from './slices/entity/entity.slice'
import auditRulesSlice from './slices/auditRules/auditRules.slice'
import auditRuleSetSlice from './slices/auditRuleSet/auditRuleSet.slice'

export default {
  auth: authSlice,
  subscription: subscriptionSlice,
  users: usersSlice,
  notification: notificationSlice,
  organizations: organizationsSlice,
  metrics: metricsSlice,
  reports: reportsSlice,
  license: licenseSlice,
  chat: chatSlice,
  modal: ModalSlice,
  // LOADERS
  authLoader: authLoadersSlice,
  usersLoaders: usersLoadersSlice,
  organizationsLoaders: organizationsLoadersSlice,
  licenseLoaders: licenseLoadersSlice,
  chatLoaders: chatLoadersSlice,
  reportLoaders: ReportLoadersSlice,
  metricsLoaders: metricsLoadersSlice,
  notificationData: notificationDataSlice,
  pendingUsers: usersSlice,
  entity: entitiySlice,
  auditRules: auditRulesSlice,
  auditRuleSet: auditRuleSetSlice
}
