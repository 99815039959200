import { UserAccountContractRoot } from '@/contracts/UserAccount.contract'
import { createAsyncAction } from '../utils/createAsyncAction'
import UsersAPI from '@/services/users/users.api'
import organizationsApi from '@/services/organizations/organizations.api'
import getErrorMessage from '@/utils/getErrorMessage'
import {
  setIsDeletingUser,
  setIsFetchingUsers,
  setIsUpdatingUser,
  setIsCreatingUser
} from '../slices/loaders/users-loaders/users-loaders.slice'
import { setUsers, setUsersError } from '../slices/users/users.slice'
import { EditUserData, CreateUserData, VerifyUserData } from '@/services/users/users.interfaces'
import { setNotification } from '../slices/notification/notification.slice'
import { USER_ROLES } from '@/enums/userRoles'

export const getUsersAccounts = createAsyncAction<UserAccountContractRoot, string | undefined>(
  'users/getUsersAccounts',
  async (filters, { dispatch, rejectWithValue }) => {
    dispatch(setIsFetchingUsers(true))

    try {
      const response = await UsersAPI.getUsers(filters)
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setUsersError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsFetchingUsers(false))
    }
  }
)
export const getPendingUsersAccounts = createAsyncAction<UserAccountContractRoot, string | undefined>(
  'users/getPendingUsersAccounts',
  async (filters, { dispatch, rejectWithValue }) => {
    dispatch(setIsFetchingUsers(true))

    try {
      const response = await UsersAPI.getUsers(filters)
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setUsersError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsFetchingUsers(false))
    }
  }
)

export const getOrganizationUsersAccounts = createAsyncAction<
  UserAccountContractRoot,
  { organizationId: number | undefined; itemsPerPage: number }
>('api/v1/organizations/{id}/users', async ({ organizationId }, { dispatch, rejectWithValue }) => {
  dispatch(setIsFetchingUsers(true))

  try {
    const response = await organizationsApi.getOrganizationUsers(organizationId ?? 0)
    const { data } = response
    return data.data
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch(setUsersError(message))
    return rejectWithValue(message)
  } finally {
    dispatch(setIsFetchingUsers(false))
  }
})

export const createUserAccount = createAsyncAction<UserAccountContractRoot, { body: CreateUserData }>(
  'users/createUserAccount',
  async ({ body }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsCreatingUser(true))

    const values = {
      ...body,
      name: `${body.first_name} ${body.last_name}`
    }

    delete values.first_name
    delete values.last_name

    try {
      const orgId = getState().auth.user?.organization.id
      await UsersAPI.createUser(values, String(orgId))
      const role = getState().auth.user?.role.name
      let response
      if (role === USER_ROLES.SUPER_ADMIN) {
        response = await UsersAPI.getUsers('limit=10&offset=0&columnAccessor=id&direction=DESC')
        setUsers(response.data)
        dispatch(getPendingUsersAccounts('limit=10&offset=0&columnAccessor=id&direction=DESC&pending=true'))
      } else {
        const resPaginated = await organizationsApi.getOrganizationUsers(orgId ?? 0)
        response = resPaginated.data
      }
      dispatch(setNotification({ message: 'User created successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    } finally {
      dispatch(setIsCreatingUser(false))
    }
  }
)

export const editUserAccount = createAsyncAction<
  UserAccountContractRoot,
  { body: EditUserData; id: number | undefined }
>('users/editUserAccount', async ({ body, id }, { dispatch, rejectWithValue, getState }) => {
  dispatch(setIsUpdatingUser(true))

  const roleId = () => {
    switch (body.role) {
      case USER_ROLES.ORG_ADMIN:
        return '2'
      case USER_ROLES.SUPER_ADMIN:
        return '1'
      default:
        return '3'
    }
  }

  try {
    const role = getState().auth.user?.role.name
    const orgId = getState().auth.user?.organization.id
    let response
    if (role === USER_ROLES.SUPER_ADMIN) {
      const values = {
        ...body,
        name: `${body.first_name} ${body.last_name}`
      }
      delete values.first_name
      delete values.last_name
      await UsersAPI.editUser(values, id ?? 0)
      response = await UsersAPI.getUsers('limit=10&offset=0&columnAccessor=id&direction=DESC')
      setUsers(response.data)
      // dispatch(getPendingUsersAccounts('limit=10&offset=0&columnAccessor=id&direction=DESC&pending=true'))
    } else {
      const values = {
        ...body,
        name: `${body.first_name} ${body.last_name}`,
        role: roleId()
      }
      delete values.first_name
      delete values.last_name
      await organizationsApi.editOrganizationUser(values, orgId ?? 0, id ?? 0)
      const resPaginated = await organizationsApi.getOrganizationUsers(orgId ?? 0)
      response = resPaginated.data
    }
    dispatch(setNotification({ message: 'User updated successfully', variant: 'success' }))
    return response.data
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch(setNotification({ message, variant: 'error' }))

    return rejectWithValue(message)
  } finally {
    dispatch(setIsUpdatingUser(false))
  }
})

export const deleteUserAccount = createAsyncAction<UserAccountContractRoot, number>(
  'users/deleteUserAccount',
  async (id, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsDeletingUser(true))

    try {
      const role = getState().auth.user?.role.name
      const orgId = getState().auth.user?.organization.id
      let response
      if (role === USER_ROLES.SUPER_ADMIN) {
        await UsersAPI.deleteUser(id)
        response = await UsersAPI.getUsers('limit=10&offset=0&columnAccessor=id&direction=DESC')
        setUsers(response.data)
        dispatch(getPendingUsersAccounts('limit=10&offset=0&columnAccessor=id&direction=DESC&pending=true'))
      } else {
        await organizationsApi.deleteOrganizationUser(orgId ?? 0, id ?? 0)
        const resPaginated = await organizationsApi.getOrganizationUsers(orgId ?? 0)
        response = resPaginated.data
      }
      dispatch(setNotification({ message: 'User deleted successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setNotification({ message, variant: 'error' }))

      return rejectWithValue(message)
    } finally {
      dispatch(setIsDeletingUser(false))
    }
  }
)

export const verifyUserAccount = createAsyncAction<
  UserAccountContractRoot,
  { body: VerifyUserData; id: number | undefined }
>('users/verifyUserAccount', async ({ body, id }, { dispatch, rejectWithValue }) => {
  dispatch(setIsUpdatingUser(true))

  try {
    await UsersAPI.verifyUser(body, id ?? 0)
    dispatch(getPendingUsersAccounts('limit=10&offset=0&columnAccessor=id&direction=DESC&pending=true'))
    const response = await UsersAPI.getUsers('limit=10&offset=0&columnAccessor=id&direction=DESC')
    setUsers(response.data)

    dispatch(setNotification({ message: `User's Application Accepted`, variant: 'success' }))
    return response.data
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch(setUsersError(message))
    return rejectWithValue(message)
  } finally {
    dispatch(setIsUpdatingUser(false))
  }
})
