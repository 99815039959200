import { NAV_LINKS } from '@/enums/navLinks'
import { USER_ROLES } from '@/enums/userRoles'
import { useAppSelector } from '@/store/hooks/store-hooks'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function LicenseGuardProvider({ children }: { children: React.ReactNode }) {
  const userLicense = useAppSelector((state) => state.license.license)
  const user = useAppSelector((state) => state.auth.user)
  const isSignedIn = useAppSelector((state) => state.auth.isSignedIn)
  // const location = useLocation()
  const navigate = useNavigate()

  const checkActiveLicense = useCallback(() => {
    if (userLicense?.status !== 'active' && user?.role.name !== USER_ROLES.SUPER_ADMIN) {
      // Redirect to license page
      console.log('Redirecting to license page')
      navigate(NAV_LINKS.LICENSE_PLAN, { replace: true })
    }
  }, [userLicense, user?.role, navigate])

  useEffect(() => {
    if (!isSignedIn) return

    checkActiveLicense()
  }, [checkActiveLicense, isSignedIn])

  return children
}

export default LicenseGuardProvider
