import { useEffect } from 'react'

const DynamicFavicon = () => {
  useEffect(() => {
    const faviconLink = document.getElementById('favicon') as HTMLLinkElement
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    function updateFavicon(matches: boolean) {
      if (matches) {
        // Dark mode
        faviconLink.href = '/images/icon-dark.png'
      } else {
        // Light mode
        faviconLink.href = '/images/icon-light.png'
      }
    }

    // Set initial favicon based on current preference
    updateFavicon(mediaQuery.matches)

    // Listen for changes
    const handler = (e: MediaQueryListEvent) => updateFavicon(e.matches)
    mediaQuery.addEventListener('change', handler)

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener('change', handler)
  }, [])

  return null // This component does not render anything
}

export default DynamicFavicon
