import { Menu } from '@mantine/core'
import styles from './NotificationMenu.module.css'
import NotificationIcon from '@/assets/images/icons/notification.png'
import { useNavigate } from 'react-router-dom'
import { NAV_LINKS } from '@/enums/navLinks'
import { NotificationData } from '@/store/slices/notificationData/notificationData.interfaces'
function NotificationMenu({ ...props }) {
  const navigate = useNavigate()
  return (
    <Menu shadow="md" width={400} data-testid="user-menu">
      <Menu.Target data-testid="menu-trigger">
        <img src={NotificationIcon} />
      </Menu.Target>
      <Menu.Dropdown className={styles.Dropdown} data-testid="menu-dropdown">
        {props.notificatioData?.map((item: NotificationData, index: number) => (
          <Menu.Item
            key={index}
            className={styles.DropdownItem}
            onClick={() => navigate(`${NAV_LINKS.REPORT_ANALYSIS}?reportId=${item.id}`)}
          >
            {item.message}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}

export default NotificationMenu
