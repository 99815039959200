import { NAV_LINKS } from '@/enums/navLinks'
import { USER_ROLES } from '@/enums/userRoles'
import { privateRoutesPerRole } from '@/router/routes'

export const getRedirectPath = (prevRoute: string, userRole?: USER_ROLES): string => {
  console.log('getRedirectPath', prevRoute, userRole)

  const baseRoute = userRole === USER_ROLES.SUPER_ADMIN ? NAV_LINKS.DASHBOARD : NAV_LINKS.REPORTS_AUDITED
  if (!userRole || !prevRoute) return baseRoute

  const prevRoutePerRole = privateRoutesPerRole.find(
    (route) => route.path === prevRoute && route.roles.includes(userRole)
  )

  if (!prevRoutePerRole && prevRoute !== NAV_LINKS.MFA_VERIFICATION) return NAV_LINKS.NOT_FOUND
  if (prevRoutePerRole) return prevRoute
  return baseRoute
}
