import styles from './Error404Page.module.css'
import errorImage from '@/assets/images/icons/error.png'
import PageTitle from '@/components/atoms/PageTitle/PageTitle'
function LoginForm() {
  return (
    <div className={styles.Container}>
      <img src={errorImage} />
      <PageTitle title="ERROR 404" />
      <p>
        This page does not exist in our systems, please use the
        <br />
        navigation bar to the left to find your desired service
      </p>
    </div>
  )
}
export default LoginForm
