import ErrorPage from '@/components/organisms/ErrorPage/Error404Page'
import styles from './ErrorTemplate.module.css'
import { Container } from '@mantine/core'

const ErrorTemplate = () => {
  return (
    <main className={styles.Wrapper}>
      <Container size="xl" className={styles.Container}>
        <ErrorPage />
      </Container>
    </main>
  )
}

export default ErrorTemplate
