import { ILicense, ILicenseRoot } from '@/contracts/License.contract'
import { EditLicenseData, LicenseRequestData } from '@/services/license/license.interfaces'
import { setNotification } from '../slices/notification/notification.slice'
import getErrorMessage from '@/utils/getErrorMessage'
import { createAsyncAction } from '../utils/createAsyncAction'
import licenseApi from '@/services/license/license.api'
import { setLicenses, setLicensesError } from '../slices/license/license.slice'
import {
  setIsFetchingLicenses,
  setIsFetchingLicense,
  setIsUpdatingLicense,
  setIsGeneratingLicense,
  setIsRenewingLicense,
  setIsSendingLicense,
  setIsCancellingLicense,
  setIsRequestingLicense,
  setIsRequestingLicenseCancel
} from '../slices/loaders/license-loaders/license-loaders.slice'

export const getLicenses = createAsyncAction<ILicenseRoot, string | undefined>(
  'license',
  async (filters, { dispatch }) => {
    dispatch(setIsFetchingLicenses(true))

    try {
      const response = await licenseApi.getAllLicenses(filters)

      dispatch(setLicenses(response.data))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)

      dispatch(
        setNotification({
          message: `Failed to get the reports. Error: ${message}`,
          variant: 'error'
        })
      )
    } finally {
      dispatch(setIsFetchingLicenses(false))
    }
  }
)

// export const getLicenses = createAsyncAction<unknown, string | undefined>('license', async (_null, { dispatch, rejectWithValue }) => {
//   dispatch(setIsFetchingLicenses(true))

//   try {
//     const response = await licenseApi.getAllLicenses()
//     return response.data
//   } catch (error) {
//     const message = getErrorMessage(error)
//     dispatch(setLicensesError(message))
//     return rejectWithValue(message)
//   } finally {
//     dispatch(setIsFetchingLicenses(false))
//   }
// })

export const getCurrentUserLicense = createAsyncAction<ILicense>(
  'license/getCurrentUserLicense',
  async (_null, { dispatch, rejectWithValue }) => {
    dispatch(setIsFetchingLicense(true))
    try {
      const response = await licenseApi.getLicense()
      // dispatch(setNotification({ message: 'License retrieved successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsFetchingLicense(false))
    }
  }
)

export const editLicense = createAsyncAction<ILicenseRoot, { body: EditLicenseData; id: number | undefined }>(
  'license/editLicense',
  async ({ body, id }, { dispatch, rejectWithValue }) => {
    dispatch(setIsUpdatingLicense(true))

    try {
      console.log(body)
      await licenseApi.editLicense(body, id ?? 0)
      const response = await licenseApi.getAllLicenses()
      await licenseApi.getLicense()
      dispatch(setNotification({ message: 'License updated successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsUpdatingLicense(false))
    }
  }
)

export const cancelLicense = createAsyncAction<ILicenseRoot, { id: number | undefined }>(
  'license/cancelLicense',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setIsCancellingLicense(true))

    try {
      await licenseApi.cancelLicense(id ?? 0)
      const response = await licenseApi.getAllLicenses()
      dispatch(setNotification({ message: 'License cancelled successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsCancellingLicense(false))
    }
  }
)

export const generateLicense = createAsyncAction<ILicenseRoot, { body: EditLicenseData }>(
  'license/generateLicense',
  async ({ body }, { dispatch, rejectWithValue }) => {
    dispatch(setIsGeneratingLicense(true))

    try {
      await licenseApi.generateLicense(body)
      const response = await licenseApi.getAllLicenses()
      await licenseApi.getLicense()
      dispatch(setNotification({ message: 'License Generated successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsGeneratingLicense(false))
    }
  }
)

export const renewLicense = createAsyncAction<ILicenseRoot, { newDate: string; id: number }>(
  'license/renewLicense',
  async ({ newDate, id }, { dispatch, rejectWithValue }) => {
    dispatch(setIsRenewingLicense(true))

    try {
      await licenseApi.renewLicense({ newDate }, id)
      const response = await licenseApi.getAllLicenses()
      dispatch(setNotification({ message: 'License updated successfully', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsRenewingLicense(false))
    }
  }
)

export const resendLicenseEmail = createAsyncAction<boolean, { id: number }>(
  'license/resendLicenseEmail',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setIsSendingLicense(true))

    try {
      const response = await licenseApi.resendLicenseEmail(id)
      dispatch(setNotification({ message: 'License Email Sent!', variant: 'success' }))
      return response.data.message
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsSendingLicense(false))
    }
  }
)

export const requestLicense = createAsyncAction<boolean, { body: LicenseRequestData }>(
  'license/request',
  async ({ body }, { dispatch, rejectWithValue }) => {
    dispatch(setIsRequestingLicense(true))

    console.log('sending request email')
    try {
      const response = await licenseApi.requestLicense(body)
      dispatch(setNotification({ message: 'License Request Email Sent!', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsRequestingLicense(false))
    }
  }
)

export const requestLicenseChange = createAsyncAction<
  boolean,
  { body: Partial<EditLicenseData>; id: number | undefined }
>('license/request-change', async ({ body, id }, { dispatch, rejectWithValue }) => {
  dispatch(setIsRequestingLicense(true))

  console.log('sending request email')
  try {
    const response = await licenseApi.requestLicenseChange(body, id ?? 0)
    dispatch(setNotification({ message: 'License Change Request Email Sent!', variant: 'success' }))
    return response.data
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch(setLicensesError(message))
    return rejectWithValue(message)
  } finally {
    dispatch(setIsRequestingLicense(false))
  }
})

export const requestLicenseCancellation = createAsyncAction<
  boolean,
  { body: { reason: string }; id: number | undefined }
>('license/request-cancellation', async ({ body, id }, { dispatch, rejectWithValue }) => {
  dispatch(setIsRequestingLicenseCancel(true))

  console.log('sending request email')
  try {
    const response = await licenseApi.requestLicenseCancellation(body, id ?? 0)
    dispatch(setNotification({ message: 'Cancellation Request Email Sent!', variant: 'success' }))
    return response.data
  } catch (error) {
    const message = getErrorMessage(error)
    dispatch(setLicensesError(message))
    return rejectWithValue(message)
  } finally {
    dispatch(setIsRequestingLicenseCancel(false))
  }
})

export const activateLicense = createAsyncAction<boolean, { licenseKey: string }>(
  'license/activateLicense',
  async ({ licenseKey }, { dispatch, rejectWithValue }) => {
    dispatch(setIsSendingLicense(true))

    try {
      const response = await licenseApi.activateLicense(licenseKey)
      dispatch(setNotification({ message: 'License Activated!', variant: 'success' }))
      return response.data
    } catch (error) {
      const message = getErrorMessage(error)
      dispatch(setLicensesError(message))
      return rejectWithValue(message)
    } finally {
      dispatch(setIsSendingLicense(false))
    }
  }
)
