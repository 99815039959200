import { createSlice } from '@reduxjs/toolkit'
import { IReportState } from './reports.interfaces'
import { logoutUser } from '@/store/actions/auth.actions'

const initialState: IReportState = {
  reports: null,
  report: null,
  isLoading: false,
  isUploading: false,
  isValidating: false,
  isGenerating: false,
  reportsPollingIds: []
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReport: (state, action) => {
      state.report = action.payload
    },
    setReports: (state, action) => {
      state.reports = action.payload
    },
    setReportsUploading: (state, action) => {
      state.isUploading = action.payload
    },
    removeReport: (state) => {
      state.report = null
    },
    setReportsPollingIds: (state, action) => {
      state.reportsPollingIds = action.payload
    },
    removeReportIdFromPolling: (state, action) => {
      state.reportsPollingIds = state.reportsPollingIds.filter((id) => id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, () => {
      return initialState
    })
  }
})

export const {
  setReport,
  setReports,
  removeReport,
  setReportsUploading,
  setReportsPollingIds,
  removeReportIdFromPolling
} = reportsSlice.actions

export default reportsSlice.reducer
