import AuthAPI from '@/services/auth/auth.api'
import {
  setIsSendingEmail,
  setIsResetPassword,
  setIsSigningIn,
  setIsSigningUp,
  setIsVerifyingMfa
} from '../slices/loaders/auth-loaders/auth-loaders.slice'
import { jwtDecode } from 'jwt-decode'
import { setLogout, setUser, setTermOfServices } from '../slices/auth/auth.slice'
import { setOrganization } from '../slices/organizations/organizations.slice'
import getErrorMessage from '@/utils/getErrorMessage'
import { createAsyncAction } from '../utils/createAsyncAction'
import {
  LoginData,
  MfaVerifyData,
  RegisterData,
  RegisterResponse,
  TermOfServicesVerifyData
} from '@/services/auth/auth.interfaces'
import { UserContract } from '@/contracts/User.contract'
import Cookie from 'js-cookie'
import { SESSION_TOKENS } from '@/constants/SESSION_TOKENS'
import { ForgotPasswordResponseInterface } from '@/services/auth/forgotPassword/forgotPassword.interface.ts'
import {
  ResetPasswordRequestInterface,
  ResetPasswordResponseInterface
} from '@/services/auth/resetPassword/resetPassword.interface.ts'

export const refreshSession = createAsyncAction<{ accessToken: string; user: UserContract }, undefined>(
  'auth/refreshSession',
  async (_, { dispatch, rejectWithValue }) => {
    const refreshToken = Cookie.get(SESSION_TOKENS.REFRESH)
    const username = Cookie.get('username')

    if (!refreshToken) {
      return rejectWithValue('No refresh token')
    }

    try {
      const response = await AuthAPI.refresh(refreshToken, username)
      const { AuthenticationResult, Organization, TermOfService } = response.data
      const { IdToken, AccessToken } = AuthenticationResult
      const user: UserContract = { ...jwtDecode(IdToken), organization: Organization }
      dispatch(setUser(user))
      dispatch(setOrganization(user.organization))
      dispatch(setTermOfServices(TermOfService))
      return { accessToken: AccessToken, user }
    } catch (error) {
      dispatch(setLogout())
      throw error
    }
  }
)

export const loginUser = createAsyncAction<{ accessToken?: string; user?: UserContract; session?: string }, LoginData>(
  'auth/loginUser',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setIsSigningIn(true))

    try {
      const response = await AuthAPI.login(payload)
      if (response.data.ChallengeName === 'CUSTOM_CHALLENGE') {
        Cookie.set('username', payload.username)
        return { session: response.data.Session }
      } else {
        const { AuthenticationResult, Organization, TermOfService } = response.data
        const { IdToken, AccessToken, RefreshToken } = AuthenticationResult
        const user: UserContract = { ...jwtDecode(IdToken), organization: Organization }
        Cookie.set(SESSION_TOKENS.REFRESH, RefreshToken)
        Cookie.set('username', user.email)
        dispatch(setUser(user))
        dispatch(setTermOfServices(TermOfService))
        return { accessToken: AccessToken, user }
      }
    } catch (error) {
      const message = getErrorMessage(error)
      return rejectWithValue(message)
    } finally {
      dispatch(setIsSigningIn(false))
    }
  }
)

export const verifyMfa = createAsyncAction<{ accessToken: string; user: UserContract }, MfaVerifyData>(
  'auth/verifyMfa',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setIsVerifyingMfa(true))

    try {
      const response = await AuthAPI.mfa(payload)
      if (response.data.ChallengeName === 'CUSTOM_CHALLENGE') {
        throw new Error('You submitted a wrong MFA Verification Code')
      }
      const { AuthenticationResult, Organization, TermOfService } = response.data
      const { IdToken, AccessToken, RefreshToken } = AuthenticationResult
      const user: UserContract = { ...jwtDecode(IdToken), organization: Organization }
      Cookie.set(SESSION_TOKENS.REFRESH, RefreshToken)
      Cookie.set('username', user.email)

      dispatch(setUser(user))
      dispatch(setTermOfServices(TermOfService))
      return { accessToken: AccessToken, user }
    } catch (error) {
      const message = getErrorMessage(error)
      return rejectWithValue(message)
    } finally {
      dispatch(setIsVerifyingMfa(false))
    }
  }
)

export const registerUser = createAsyncAction<RegisterResponse, RegisterData>(
  'auth/registerUser',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setIsSigningUp(true))

    try {
      const response = await AuthAPI.register(payload)
      return { token: 'token', user: response.data }
    } catch (error) {
      const message = getErrorMessage(error)
      return rejectWithValue(message)
    } finally {
      dispatch(setIsSigningUp(false))
    }
  }
)

export const forgetPassword = createAsyncAction<ForgotPasswordResponseInterface, LoginData['username']>(
  'auth/forgotPassword',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setIsSendingEmail(true))

    try {
      const result = await AuthAPI.forgotPassword({ username: payload })
      return { message: result.data.message }
    } catch (error) {
      const message = getErrorMessage(error)
      return rejectWithValue(message)
    } finally {
      dispatch(setIsSendingEmail(false))
    }
  }
)

export const resetPassword = createAsyncAction<ResetPasswordResponseInterface, ResetPasswordRequestInterface>(
  'auth/resetPassword',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setIsResetPassword(true))

    try {
      const result = await AuthAPI.resetPassword(payload)
      return { message: result.data.message }
    } catch (error) {
      const message = getErrorMessage(error)
      return rejectWithValue(message)
    } finally {
      dispatch(setIsResetPassword(false))
    }
  }
)

export const logoutUser = createAsyncAction<unknown>(
  'auth/logoutUser',
  async (_, { getState, dispatch, rejectWithValue }) => {
    Cookie.remove(SESSION_TOKENS.REFRESH)
    Cookie.remove('username')
    const token = getState().auth.token

    try {
      await AuthAPI.logout(token)
    } catch (error) {
      const message = getErrorMessage(error)
      rejectWithValue(message)
    } finally {
      dispatch(setLogout())
    }
  }
)

// export const verifyTermOfServices = createAsyncAction<{ accessToken: string; user: UserContract }, TermOfServicesVerifyData>(
//   'auth/verifyMfa',
//   async (payload, { dispatch, rejectWithValue }) => {
//     dispatch(setIsVerifyingMfa(true))

//     try {
//       const response = await AuthAPI.verifyTermsOfServices(payload)
//       if (response.data.ChallengeName === 'CUSTOM_CHALLENGE') {
//         throw new Error('You submitted a wrong MFA Verification Code')
//       }
//       const { AuthenticationResult, Organization } = response.data
//       const { IdToken, AccessToken, RefreshToken } = AuthenticationResult
//       const user: UserContract = { ...jwtDecode(IdToken), organization: Organization }
//       Cookie.set(SESSION_TOKENS.REFRESH, RefreshToken)
//       Cookie.set('username', user.email)

//       dispatch(setUser(user))
//       return { accessToken: AccessToken, user }
//     } catch (error) {
//       const message = getErrorMessage(error)
//       return rejectWithValue(message)
//     } finally {
//       dispatch(setIsVerifyingMfa(false))
//     }
//   }
// )

export async function verifyTermOfServices(data: TermOfServicesVerifyData) {
  try {
    const response = await AuthAPI.verifyTermsOfServices(data)
    console.log(response, 'response')
  } catch (error) {
    // Handle errors appropriately
    console.error('Error polling report result:', error)
    throw error
  }
}
