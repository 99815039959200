import { useState, useRef, useEffect } from 'react'
import { TermOfServicesModalProps } from './TermOfServicesModal.intefaces'
import styles from './TermOfServicesModal.module.css'
import Button from '@/components/atoms/Button/Button'

function TermOfServicesModal({ onSubmit }: TermOfServicesModalProps) {
  // const dispatch = useAppDispatch()
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollableDiv = contentRef.current

    if (scrollableDiv) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollableDiv
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setIsScrolledToEnd(true)
        } else {
          setIsScrolledToEnd(false)
        }
      }

      scrollableDiv.addEventListener('scroll', handleScroll)
      return () => {
        scrollableDiv.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleSubmit = () => {
    console.log('handleSubmit', 'handleSubmit ada')
    onSubmit()
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.textContainer} ref={contentRef} id="scrollableDiv">
        <h1 className={styles.mainHeading}>Terms of Service</h1>
        <p>END USER LICENSE AGREEMENT</p>
        <p>
          This End User License Agreement (the “<strong>Agreement</strong>”) is a binding agreement between GAS AI, Inc.
          (“<strong>GAS AI</strong>”), and the user (hereinafter, “<strong>Licensee</strong>”) using the Services (as
          defined below) that is the subject of this Agreement.
        </p>
        <p>
          GAS AI PROVIDES THE SERVICES SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE
          CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THIS AGREEMENT. BY USING THE SERVICES, CLICKING “I AGREE” OR
          BY DOWNLOADING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B)
          ACCEPT THE AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY THE TERMS SET FORTH BELOW AND INCORPORATED
          HEREIN; AND (C) REPRESENT AND WARRANT THAT YOU ARE 18 YEARS OF AGE. IF YOU DO NOT SO AGREE AND REPRESENT AND
          WARRANT, DO NOT USE THE SERVICES AS GAS AI WILL NOT AND DOES NOT LICENSE THE RIGHT TO USE THE SERVICES.
        </p>
        <ol>
          <li>
            <span className={styles.underline}>Definitions</span>. In addition to those terms otherwise defined herein,
            for purposes of this Agreement, the following terms have the following meanings:
            <p>
              <span className={styles.indentFirstLine}>
                “<strong>Intellectual Property Rights</strong>” means any and all registered and unregistered rights
                granted, applied for or otherwise now or hereafter in existence under or related to any patent,
                copyright, trademark, trade secret, database protection or other intellectual property rights laws, and
                all similar or equivalent rights or forms of protection, in any part of the world.
              </span>
            </p>
            <p>
              <span className={styles.indentFirstLine}>
                “<strong>Person</strong>” means an individual, corporation, partnership, joint venture, limited
                liability company, governmental authority, unincorporated organization, trust, association or other
                entity.
              </span>
            </p>
            <p>
              <span className={styles.indentFirstLine}>
                “<strong>Third Party</strong>” means any Person other than Licensee or GAS.
              </span>
            </p>
            <p>
              <span className={styles.indentFirstLine}>
                “<strong>Services</strong>” collectively means the software-as-a-service and related databases,
                information and content the access of which prompted Licensee to agree to the terms herein.
              </span>
            </p>
          </li>
          <li>
            <span className={styles.underline}>License</span>. Subject to Licensee’s compliance with the terms and
            conditions of this Agreement, as well as an applicable agreement, purchase order or statement of work among
            Licensee and GAS AI (if any), GAS AI hereby grants Licensee a limited, non-exclusive, nonsublicenseable,
            nontransferable, revocable, license to access and use the Services solely for Licensee’s internal use (the “
            <strong>License</strong>”). GAS AI reserves all other rights not expressly licensed hereunder.
          </li>
          <li>
            <span className={styles.underline}>Restrictions</span>. Licensee shall not directly or indirectly: (a) allow
            Third Parties to use or access the Services without GAS AI’s prior written consent; (b) copy, use or access
            the Services in any commercial application or service except as expressly provided in this Agreement; (c)
            sell, rent, lease, lend, license or sublicense the Services to any Third Party; (d) decompile, disassemble
            or try to reverse engineer the Services, in whole or in part; (e) write or develop or attempt to distribute
            any derivative of the Services; (f) use the Services in violation of any law, agreement, regulation or rule;
            (g) remove, disable, circumvent or otherwise create or implement any workaround to any copy protection,
            rights management or security features in or protecting the Services; (h) use the Services for purposes of
            competitive analysis, the development of a competing product or service or any other purpose that is to GAS
            AI’s commercial disadvantage or detriment; or (i) violate this Agreement.
          </li>
          <li>
            <span className={styles.underline}>Responsibility for Use of Services</span>. Licensee is responsible and
            liable for all access and use of the Services by Licensee or its representatives, whether or not such access
            or use is permitted by Licensee or in violation of this Agreement. Licensee agrees to notify GAS AI
            immediately of any actual or suspected unauthorized access to or use of Services or breach of this
            Agreement.
          </li>
          <li>
            <span className={styles.underline}>No Reliance on Information Provided</span>.{' '}
            <strong>
              THE SERVICES ARE PROVIDED AS A BETA TEST ONLY AND SHOULD NOT BE RELIED UPON FOR ANY LEGAL OR BUSINESS
              DECISIONS
            </strong>
            . Licensee agrees to contact GAS AI directly in the event Licensee requires specific services related to the
            Services. Unless otherwise agreed to in writing, GAS AI has no obligation to provide any update for or
            maintenance or support or any level of service with respect to the Services.
          </li>
          <li>
            <span className={styles.underline}>Intellectual Property</span>. Licensee acknowledges and agrees that the
            Services is provided under license, and not sold, to Licensee. GAS AI shall retain all right, title and
            interest in and to the Services and all aspects thereof, as well as all improvements in, modifications to,
            and/or derivative works thereof and all of Licensee’s feedback and commentary concerning any of the
            foregoing, together with all Intellectual Property Rights in any of the foregoing, whether or not developed,
            conceived and/or actually reduced to practice by GAS AI solely or jointly with others (collectively, “
            <strong>GAS AI IP</strong>”); except for the limited License granted herein, nothing herein shall be
            construed as a license, assignment or other transfer of any GAS AI IP. To the extent Licensee acquires
            rights in or to any GAS AI IP, Licensee hereby agrees to and does hereby grant, assign and transfer the same
            to GAS AI, without the requirement of additional consideration.
          </li>
          <li>
            <span className={styles.underline}>Confidentiality</span>. Licensee agrees to protect and safeguard the
            confidentiality of the Services and GAS AI IP (“<strong>Confidential Information</strong>”) with at least
            the same degree of care as it would protect Licensee’s own confidential information, but in no event with no
            less than a reasonable degree of care. Licensee agrees not to share Confidential Information with Third
            Parties (including allowing use of the Services by Third Parties) or use Confidential Information in any
            manner except to exercise the limited license hereunder with GAS AI’s prior written consent.
          </li>
          <li>
            <span className={styles.underline}>Equitable Relief</span>. Licensee agrees and acknowledges that any actual
            or threatened breach of this Agreement by Licensee will cause injury to GAS for which money damages may be
            an inadequate remedy and that, in addition to remedies at law, GAS AI is entitled to equitable relief as a
            remedy for any such breach without the necessity of posting bond.
          </li>
          <li>
            <span className={styles.underline}>Disclaimer of Warranties; Limitation of Liability</span>. THE SERVICES
            ARE PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM
            EXTENT PERMITTED UNDER APPLICABLE LAW, GAS, ITS AFFILIATES AND THEIR RESPECTIVE EMPLOYEES, AGENTS, OFFICERS,
            OWNERS, DIRECTORS AND REPRESENTATIVES (THE “<strong>RELEASED PARTIES</strong>”) DISCLAIM ALL WARRANTIES,
            WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE SERVICES, INCLUDING ALL IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES
            THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION
            TO THE FOREGOING, THE RELEASED PARTIES PROVIDE NO WARRANTY OR UNDERTAKING, AND MAKE NO REPRESENTATION OF ANY
            KIND THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH
            ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
            RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE RELEASED PARTIES BE LIABLE FOR ANY DAMAGES
            WHATSOEVER, WHETHER DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, SPECIAL OR PUNITIVE,
            ARISING FROM OR RELATED TO USE OF OR INABILITY TO USE THE SERVICES. THE FOREGOING LIMITATIONS WILL APPLY
            REGARDLESS OF THE FORM OF THE ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE) AND
            REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </li>
          <li>
            <span className={styles.underline}>Miscellaneous</span>. This Agreement constitutes the entire understanding
            of the parties relating to the subject matter herein, and revokes and supersedes all prior agreements
            regarding the same. To the extent there is a conflict with the terms of this Agreement and any other
            agreement among the parties, the express terms of this Agreement shall govern unless expressly stated
            otherwise. This Agreement shall not be assignable or otherwise transferable by Licensee without the prior
            written consent of GAS AI, and any assignment or transfer made in violation of this provision shall be void.
            GAS AI may assign or transfer this Agreement without Licensee’s consent. This Agreement shall be construed
            without regard to any presumption or rule requiring construction or interpretation against the party
            drafting an instrument or causing any instrument to be drafted. If any provision of this Agreement is held
            to be or becomes invalid, illegal or unenforceable, such provision or provisions shall be reformed to be as
            nearly possible to the intent of the parties and the remainder of the Agreement shall not be affected and
            shall remain valid and enforceable to the greatest extent permitted by law. This Agreement shall be binding
            on and shall inure to the benefit of the parties hereto, and their heirs, administrators, successors and
            assigns.
          </li>
        </ol>
      </div>
      <div className={styles.buttonContainer}>
        <Button label="Accept" disabled={!isScrolledToEnd} onClick={handleSubmit} />
      </div>
    </div>
  )
}

export default TermOfServicesModal
