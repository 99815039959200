import generateLicense from './generateLicense/generateLicense'
import activateLicense from './activateLicense/activateLicense'
import getAllLicenses from './getAllLicenses/getAllLicenses'
import getLicense from './getLicense/getLicense'
import editLicense from './editLicense/editLicense'
import cancelLicense from './cancelLicense/cancelLicense'
import renewLicense from './renewLicense/renewLicense'
import requestLicense from './requestLicense/requestLicense'
import requestLicenseChange from './requestLicenseChange/requestLicenseChange'
import resendLicenseEmail from './resendLicenseEmail/resendLicenseEmail'
import requestLicenseCancellation from './requestLicenseCancellation/requestLicenseCancellation'

export default {
  generateLicense,
  activateLicense,
  getAllLicenses,
  getLicense,
  editLicense,
  cancelLicense,
  renewLicense,
  requestLicense,
  requestLicenseChange,
  resendLicenseEmail,
  requestLicenseCancellation
}
