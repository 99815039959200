import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  forgetPassword,
  loginUser,
  refreshSession,
  registerUser,
  resetPassword,
  verifyMfa
} from '../../actions/auth.actions'
import { IAuthState } from './auth.interfaces'
import { UserContract } from '@/contracts/User.contract'

const initialState: IAuthState = {
  isSignedIn: false,
  token: null,
  authMessage: undefined,
  user: null,
  termOfServices: 1
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserContract>) => {
      state.user = action.payload
    },
    setLogout: (state) => {
      state.isSignedIn = false
      state.token = null
      state.authMessage = undefined
      state.user = null
    },
    clearAuthMessage: (state) => {
      state.authMessage = undefined
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setTermOfServices: (state, action: PayloadAction<number>) => {
      state.termOfServices = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isSignedIn = true
        state.token = action.payload.accessToken ?? ''
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.authMessage = { type: 'error', message: action.payload ?? '' }
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.authMessage = { type: 'success', message: action.payload.message ?? '' }
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.authMessage = { type: 'error', message: action.payload ?? '' }
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.authMessage = { type: 'success', message: action.payload.message ?? '' }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.authMessage = { type: 'error', message: action.payload ?? '' }
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isSignedIn = true
        state.token = action.payload.token
        state.user = action.payload.user
        state.authMessage = { type: 'success', message: 'Please verify your email to complete your registration' }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.authMessage = { type: 'error', message: action.payload ?? '' }
      })
      .addCase(refreshSession.fulfilled, (state, action) => {
        state.token = action.payload.accessToken
        state.isSignedIn = true
      })
      .addCase(verifyMfa.fulfilled, (state, action) => {
        state.isSignedIn = true
        state.token = action.payload.accessToken
      })
      .addCase(verifyMfa.rejected, (state, action) => {
        state.authMessage = { message: action.payload ?? '', type: 'error' }
      })
  }
})

export const { setUser, setLogout, clearAuthMessage, setToken, setTermOfServices } = authSlice.actions

export default authSlice.reducer
