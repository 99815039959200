import { ReportDetails } from '@/contracts/ReportDetail.contract'
import { requests } from '@/libs/axios/axios'
import { AxiosResponse } from 'axios'
import { ReportsEndpoints } from '../reportsEndpoints'

export const getReportResult = async (reportId: number): Promise<AxiosResponse<ReportDetails>> => {
  return await requests.get(`${ReportsEndpoints.AUDIT_REPORTS}/${reportId}`)
}
export const getReportStatus = async (reportId: number): Promise<AxiosResponse<ReportDetails>> => {
  return await requests.get(`/audits/${reportId}${ReportsEndpoints.AUDIT_REPORTS_STATUS}`)
}
