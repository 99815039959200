import { requests } from '@/libs/axios/axios'
import { OrganizationsEndpoints } from '../organizations-endpoints'
import { AxiosResponse } from 'axios'
import { OrganizationRoot } from '@/contracts/Organization.contract'

const getOrganizations = async (filters?: string): Promise<AxiosResponse<OrganizationRoot>> => {
  let url: string = OrganizationsEndpoints.ORGANIZATIONS

  if (filters) {
    url = `${url}?${filters}`
  }
  return requests.get(url)
}

export default getOrganizations
