import { createSlice } from '@reduxjs/toolkit'
import { MetricsLoadersState } from './metrics-loaders.interface'

const initialState: MetricsLoadersState = {
  isGettingData: false,
  isGettingTotalUploads: false,
  isGettingReportStatus: false,
  isGettingActionsTaken: false,
  isGettingReportViewedAndDownloaded: false,
  isGettingReportHoursSaved: false
}

const metricsLoadersSlice = createSlice({
  name: 'metricsLoaders',
  initialState,
  reducers: {
    setIsGettingData: (state, action) => {
      state.isGettingData = action.payload
    },
    setIsGettingTotalUploads: (state, action) => {
      state.isGettingTotalUploads = action.payload
    },
    setIsGettingReportStatus: (state, action) => {
      state.isGettingReportStatus = action.payload
    },
    setIsGettingActionsTaken: (state, action) => {
      state.isGettingActionsTaken = action.payload
    },
    setIsGettingReportViewedAndDownloaded: (state, action) => {
      state.isGettingReportViewedAndDownloaded = action.payload
    },
    setIsGettingReportHoursSaved: (state, action) => {
      state.isGettingReportHoursSaved = action.payload
    }
  }
})

export const {
  setIsGettingData,
  setIsGettingTotalUploads,
  setIsGettingReportStatus,
  setIsGettingActionsTaken,
  setIsGettingReportViewedAndDownloaded,
  setIsGettingReportHoursSaved
} = metricsLoadersSlice.actions

export default metricsLoadersSlice.reducer
