import { NavLink, useNavigate } from 'react-router-dom'
import styles from './MenuItem.module.css'
import { MenuItemProps } from './MenuItem.interfaces'
import { Text } from '@mantine/core'
import { useAppSelector } from '@/store/hooks/store-hooks'
import { NAV_LINKS } from '@/enums/navLinks'
import { USER_ROLES } from '@/enums/userRoles'

function MenuItem({ Icon, title, checkLicense = false, href }: MenuItemProps) {
  const userLicense = useAppSelector((state) => state.license.license)
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.auth.user)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()

    if (checkLicense && userLicense?.status !== 'active' && user?.role.name !== USER_ROLES.SUPER_ADMIN) {
      // Redirect to license page
      navigate(NAV_LINKS.LICENSE_PLAN, { replace: true })
    } else {
      navigate(href)
    }
  }

  return (
    <NavLink
      data-testid="menu-item"
      to={href}
      onClick={handleClick}
      className={({ isActive }) => `${styles.MenuItem} ${isActive && styles.ActiveItem}`}
    >
      <span className={styles.Icon}>{Icon}</span>
      <Text fw="bold">{title}</Text>
    </NavLink>
  )
}

export default MenuItem
