import { createSlice } from '@reduxjs/toolkit'
import { ChatSliceState } from './chat-slice.interfaces'
import { logoutUser } from '@/store/actions/auth.actions'
import { createChat } from '@/store/actions/chat.actions'

const initialState: ChatSliceState = {
  history: [],
  mostAskedQuestions: [],
  error: '',
  createdChat: null
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setHistoryChat(state, action) {
      state.history = action.payload
    },
    setMostAskedQuestions(state, action) {
      state.mostAskedQuestions = action.payload
    },
    setChatError(state, action) {
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.fulfilled, () => {
        return initialState
      })
      .addCase(createChat.fulfilled, (state, action) => {
        state.createdChat = action.payload
      })
  }
})

export const { setHistoryChat, setMostAskedQuestions, setChatError } = chatSlice.actions

export default chatSlice.reducer
