import { requests } from '@/libs/axios/axios'
import { OrganizationsEndpoints } from '../organizations-endpoints'
import { AxiosResponse } from 'axios'
import { Organization } from '@/contracts/Organization.contract'

async function getOrganizationById(id: number): Promise<AxiosResponse<Organization>> {
  return requests.get(`${OrganizationsEndpoints.ORGANIZATION}/${id}`)
}

export default getOrganizationById
