import { createSlice } from '@reduxjs/toolkit'
import { IAuditRulesState } from './auditRules.interfaces'
const initialState: IAuditRulesState = {
  auditRules: {
    auditRules: [],
    total: 0
  }
}
const auditRulesSlice = createSlice({
  name: 'auditRules',
  initialState,
  reducers: {
    setAuditRules(state, action) {
      state.auditRules.auditRules = action.payload?.data
      state.auditRules.total = action.payload?.total
    }
  }
})

export const { setAuditRules } = auditRulesSlice.actions

export default auditRulesSlice.reducer
