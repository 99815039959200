import { createSlice } from '@reduxjs/toolkit'
import { MetricsState } from './metrics.interfaces'
import { logoutUser } from '@/store/actions/auth.actions'
import { getUploadedByUserData } from '@/store/actions/dashboard.actions'

const initialState: MetricsState = {
  totalUploads: [],
  reportStatus: [],
  actionsTaken: [],
  reportViewedAndDownloaded: [],
  uploadedByUser: [],
  hoursSaved: []
}

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setTotalUploads: (state, action) => {
      state.totalUploads = action.payload
    },
    setReportStatus: (state, action) => {
      state.reportStatus = action.payload
    },
    setActionsTaken: (state, action) => {
      state.actionsTaken = action.payload
    },
    setReportsViewedAndDownloaded: (state, action) => {
      state.reportViewedAndDownloaded = action.payload
    },
    setHoursSaved: (state, action) => {
      state.hoursSaved = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUploadedByUserData.fulfilled, (state, action) => {
        state.uploadedByUser = action.payload
      })
      .addCase(logoutUser.fulfilled, () => initialState)
  }
})

export const { setTotalUploads, setReportStatus, setActionsTaken, setReportsViewedAndDownloaded, setHoursSaved } =
  metricsSlice.actions

export default metricsSlice.reducer
