import { UserContract } from '@/contracts/User.contract'
import { NAV_LINKS } from '@/enums/navLinks'
import { USER_ROLES } from '@/enums/userRoles'

interface UserMenuRoute {
  label: string
  route: NAV_LINKS
  roles: Array<UserContract['role']['name']>
  testId: string
}

export const userMenuRoutes: UserMenuRoute[] = [
  {
    label: 'Manage Accounts',
    route: NAV_LINKS.MANAGE_ACCOUNTS,
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN],
    testId: 'manage-account-menu'
  },
  {
    label: 'Acquire License',
    route: NAV_LINKS.LICENSE_PLAN,
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN],
    testId: 'acquire-license-menu'
  },
  {
    label: 'Your License',
    route: NAV_LINKS.LICENSE_MANAGEMENT,
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE],
    testId: 'your-license-menu'
  },
  {
    label: 'Institutions Management',
    route: NAV_LINKS.ORGANIZATIONS_MANAGEMENT,
    roles: [USER_ROLES.SUPER_ADMIN],
    testId: 'institutions-admin-menu'
  },
  {
    label: 'Licenses Management',
    route: NAV_LINKS.LICENSES_ADMINISTRATION,
    roles: [USER_ROLES.SUPER_ADMIN],
    testId: 'license-admin-menu'
  },
  {
    label: 'Settings',
    route: NAV_LINKS.SETTINGS,
    roles: [USER_ROLES.SUPER_ADMIN],
    testId: 'settings-admin-menu'
  },
  // {
  //   label: 'FAQ',
  //   route: NAV_LINKS.FAQS,
  //   roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE],
  //   testId: 'faqs-us-admin-menu'
  // },
  {
    label: 'Contact Us',
    route: NAV_LINKS.CONTACT_US,
    roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ORG_ADMIN, USER_ROLES.EMPLOYEE],
    testId: 'contact-us-admin-menu'
  }
]
