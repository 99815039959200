import { requests } from '@/libs/axios/axios'
// import { AxiosResponse } from 'axios'
import { ChatEndpoints } from '../chat-endpoints'

export const sendFeedback = async (data: {
  message_id: string
  path_id: string
  chat_id: string
  answer_feedback: number
}) => {
  return requests.post(ChatEndpoints.SEND_FEEDBACK, data)
}
