import { requests } from '@/libs/axios/axios'
import { UsersEndpoints } from '../users-endpoints'
import { AxiosResponse } from 'axios'
import { UserAccountContractRoot } from '@/contracts/UserAccount.contract'

const getUsers = async (filters?: string): Promise<AxiosResponse<UserAccountContractRoot>> => {
  let url: string = UsersEndpoints.USERS
  if (filters) {
    url = `${url}?${filters}`
  }
  return requests.get(url)
}

export default getUsers
