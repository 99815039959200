import { createSlice } from '@reduxjs/toolkit'
import { ChatLoadersSliceState } from './chat-loaders-slice.interface'

const initialState: ChatLoadersSliceState = {
  isHistoryLoading: false,
  isMostAskedQuestionsLoading: false,
  isRegeneratingMessage: false,
  isLoadingMessage: false,
  isSendingFeedback: false
}

const chatLoadersSlice = createSlice({
  name: 'chatLoaders',
  initialState,
  reducers: {
    setHistoryLoading(state, action) {
      state.isHistoryLoading = action.payload
    },
    setMostAskedQuestionsLoading(state, action) {
      state.isMostAskedQuestionsLoading = action.payload
    }
  }
})

export const { setHistoryLoading, setMostAskedQuestionsLoading } = chatLoadersSlice.actions

export default chatLoadersSlice.reducer
